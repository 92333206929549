<template>
  <div
    :style="slidePosition"
    class="h-100 z-7 d-flex justify-content-center align-items-center"
    style="width:100%;"
  >
    <div
      class="position-absolute"
      style="left:75%; top:70%; transform:translate(-50%,-50%)"
    >
      <div class="fireworks position-relative">
        <div
          v-for="(explotion, index) in 12"
          :key="explotion"
          :style="
            'width:0.25vh;height:16vh;left:-0.08vh; overflow: hidden;transform-origin:50% 100%;transform:rotate(' +
              index * 30 +
              'deg) translateY(0vh);'
          "
          class="explotion position-absolute e1"
        ></div>
        <div
          v-for="(explotion, index) in 18"
          :key="explotion"
          :style="
            'width:0.5vh;height:16vh;left:-0.08vh; overflow: hidden;transform-origin:50% 100%;transform:rotate(' +
              index * 20 +
              'deg) translateY(0vh) rotate(45deg);'
          "
          class="explotion position-absolute e2"
        ></div>
      </div>
    </div>
    <div
      class="position-absolute"
      style="left:20%; top:60%; transform:translate(-50%,-50%)"
    >
      <div class="fireworks position-relative">
        <div
          v-for="(explotion, index) in 12"
          :key="explotion"
          :style="
            'width:0.25vh;height:16vh;left:-0.08vh; overflow: hidden;transform-origin:50% 100%;transform:rotate(' +
              index * 30 +
              'deg) translateY(0vh);'
          "
          class="explotion position-absolute e3"
        ></div>
        <div
          v-for="(explotion, index) in 18"
          :key="explotion"
          :style="
            'width:0.5vh;height:16vh;left:-0.08vh; overflow: hidden;transform-origin:50% 100%;transform:rotate(' +
              index * 20 +
              'deg) translateY(0vh) rotate(45deg);'
          "
          class="explotion position-absolute e4"
        ></div>
      </div>
    </div>
    <div
      class="position-absolute"
      style="left:85%; top:5%; transform:translate(-50%,-50%)"
    >
      <div class="fireworks position-relative">
        <div
          v-for="(explotion, index) in 18"
          :key="explotion"
          :style="
            'width:0.25vh;height:26vh;left:-0.08vh; overflow: hidden;transform-origin:50% 100%;transform:rotate(' +
              index * 20 +
              'deg) translateY(0vh);'
          "
          class="explotion position-absolute e5"
        ></div>
      </div>
    </div>
    <div
      class="position-absolute"
      style="left:85%; top:10%; transform:translate(-50%,-50%)"
    >
      <div class="fireworks position-relative">
        <div
          v-for="(explotion, index) in 24"
          :key="explotion"
          :style="
            'width:0.4vh;height:22vh;left:-0.08vh; overflow: hidden;transform-origin:50% 100%;transform:rotate(' +
              index * 15 +
              'deg) translateY(0vh) rotate(45deg);'
          "
          class="explotion position-absolute e6"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
/* import anime from "animejs"
import { normalize } from "@/utils/ranges" */
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() {},
  methods: {},
  watch: {
    innerPosition() {
      //this.bluePrintRef(this.innerPosition)
    }
  }
}
</script>
<style lang="scss" scoped>
.explotion::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  border-radius: 15%;
  opacity: 0;
  background-color: $warning;
  animation-name: explotion;
  animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
  animation-iteration-count: infinite;
}
.e1::before {
  animation-delay: 2s;
  animation-duration: 1.8s;
}
.e2::before {
  animation-delay: 2.5s;
  animation-duration: 1.8s;
}

.e3::before {
  animation-delay: 3s;
  animation-duration: 2.6s;
}
.e4::before {
  animation-delay: 4s;
  animation-duration: 2.6s;
}

.e5::before {
  animation-delay: 4s;
  animation-duration: 3s;
}
.e6::before {
  animation-delay: 5s;
  animation-duration: 3s;
}
@keyframes explotion {
  0% {
    top: 100%;
    opacity: 0;

    height: 0vh;
  }
  23%,
  48% {
    opacity: 1;
    height: 4vh;
  }
  100% {
    top: -50%;
    opacity: 0;
    height: 0vh;
  }
}
</style>
